const productsDB = [
  {
    _id: "1",
    images: [
      {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5_ZU2n-vbqrdNVN-IMSdEQTSiobtpmW6owrkDRraZytESTulFw-2yaC5t3rzu2pTchs&usqp=CAU",
      },
      {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5_ZU2n-vbqrdNVN-IMSdEQTSiobtpmW6owrkDRraZytESTulFw-2yaC5t3rzu2pTchs&usqp=CAU",
      },
    ],
    name: "Product 1",
    price: 19.99,
    stock: 10,
  },
  {
    _id: "2",
    images: [
      {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5_ZU2n-vbqrdNVN-IMSdEQTSiobtpmW6owrkDRraZytESTulFw-2yaC5t3rzu2pTchs&usqp=CAU",
      },
    ],
    name: "Product 2",
    price: 29.99,
    stock: 5,
  },
  {
    _id: "3",
    images: [
      {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5_ZU2n-vbqrdNVN-IMSdEQTSiobtpmW6owrkDRraZytESTulFw-2yaC5t3rzu2pTchs&usqp=CAU",
      },
    ],
    name: "Product 3",
    price: 9.99,
    stock: 15,
  },
  {
    _id: "4",
    images: [
      {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5_ZU2n-vbqrdNVN-IMSdEQTSiobtpmW6owrkDRraZytESTulFw-2yaC5t3rzu2pTchs&usqp=CAU",
      },
    ],
    name: "Product 4",
    price: 39.99,
    stock: 3,
  },
  {
    _id: "5",
    images: [
      {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5_ZU2n-vbqrdNVN-IMSdEQTSiobtpmW6owrkDRraZytESTulFw-2yaC5t3rzu2pTchs&usqp=CAU",
      },
    ],
    name: "Product 5",
    price: 49.99,
    stock: 8,
  },
  {
    _id: "6",
    images: [
      {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5_ZU2n-vbqrdNVN-IMSdEQTSiobtpmW6owrkDRraZytESTulFw-2yaC5t3rzu2pTchs&usqp=CAU",
      },
    ],
    name: "Product 6",
    price: 14.99,
    stock: 20,
  },
  {
    _id: "7",
    images: [
      {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5_ZU2n-vbqrdNVN-IMSdEQTSiobtpmW6owrkDRraZytESTulFw-2yaC5t3rzu2pTchs&usqp=CAU",
      },
    ],
    name: "Product 7",
    price: 24.99,
    stock: 12,
  },
  {
    _id: "8",
    images: [
      {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5_ZU2n-vbqrdNVN-IMSdEQTSiobtpmW6owrkDRraZytESTulFw-2yaC5t3rzu2pTchs&usqp=CAU",
      },
    ],
    name: "Product 8",
    price: 34.99,
    stock: 6,
  },
  {
    _id: "9",
    images: [
      {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5_ZU2n-vbqrdNVN-IMSdEQTSiobtpmW6owrkDRraZytESTulFw-2yaC5t3rzu2pTchs&usqp=CAU",
      },
    ],
    name: "Product 9",
    price: 54.99,
    stock: 2,
  },
  {
    _id: "10",
    images: [
      {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5_ZU2n-vbqrdNVN-IMSdEQTSiobtpmW6owrkDRraZytESTulFw-2yaC5t3rzu2pTchs&usqp=CAU",
      },
    ],
    name: "Product 10",
    price: 29.99,
    stock: 10,
  },
];
export default productsDB;
