import React from "react";
import styles from "./Navbar.module.scss";

const Navbar = () => {
    return (
        <div className={styles.navbar}>
            <h3>Admin</h3>
        </div>
    );
};

export default Navbar;
